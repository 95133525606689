<template id="accepted">
    <div class="card col-md-8 rounded shadow-lg"  align="center">
      <br>
      <p style="font-size:18px">Su petición ha sido registrada y se ha radicado con el número:</p>
      <p style="font-size:24px; color:red">{{ rad }}</p>
      <p style="font-size:18px">Tenga en cuenta este radicado para consultar posteriormente el estado de su petición.</p>
      <p style="font-size:16px">Se ha enviado una copia de su Pqrs al correo electrónico suministrado.</p>
      <p style="font-size:16px">Si no encuentra el mensaje en la bandeja de entrada, revise la carpeta de correo no deseado o spam y marque el mensaje como <b>correo deseado o no spam</b>.</p>
    </div>
</template>
<script>
export default {
  data(){
    return{
      rad: ''
    }
  },
  created(){
    if (this.$route.params.tipo){
      let uri = "/tpocors/edit/" + this.$route.params.tipo;
      this.axios.get(uri)
      .then((response) => {
        this.rad = response.data.tcr_cdgo + "-" + this.$route.params.ano + "-" + this.$route.params.numero;
        var host = window.location.origin;

        /**  EMAIL */
        let uri2 = "/api/email";
        this.axios.post(uri2, { cmp_id: String(this.$store.state.company), action: 'pqrs', email: this.$route.params.email, rad: this.rad, nombre: this.$route.params.nombre, empresa: this.$store.state.companydata.cmp_nmbre, host: host, adjuntos: this.$route.params.adjuntos })
        .then((response) => {
    
        })
        .catch((err) => {
          alert('No se pudo enviar el correo electrónico de confirmación. ' + err);
        })
        /** END EMAIL */
      });
    } else {
      this.$router.replace({ name: 'NewPqrs' });
    }
  },
  methods: {

  }    
}
</script>
